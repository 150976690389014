// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

/** Replace the width of the container **/
.container {
    width: 1360px;
    @media screen and (max-width: 1400px) {
        width: 1200px;
    }
    @media screen and (max-width: 1024px) {
        width: 100%;
    }
}

h1, h2, h3, h4, h5 {
    font-family: $font-family-2;
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';





/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-text {
    background: #000;
    padding: 50px 0;
    h3 {
        text-transform: initial;
        font-size: $font-size-40;
        font-weight: normal;
        color: $color-white;
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff;
        padding: 5px 0;
        width: 45%;
        margin-bottom: 50px;
    }
    p {
        font-size: $font-size-22;
        font-weight: normal;
        color: $color-white;
        text-align: justify;
        margin: 0;
        line-height: 1.3;
    }
    .left-right {
        display: flex;
        .left-side {
            width: 45%;
            margin-right: 9%;
        }
        .right-side {
            width: 46%;
            .logo {
                padding-top: 25px;
                display: flex;
                justify-content: right;
            }
        }
    }
}

#section-list-carousel {
    background: #b2b2b2;
    padding: 50px 0;
    .left-side {
        width: 50%;
        h3 {
            text-transform: initial;
            font-size: $font-size-36;
            font-weight: normal;
            color: $color-black;
            text-decoration: underline;
        }
        ul {
            list-style-image: url(../images/accueil_section02_point_jaune.jpg);
            padding-left: 30px;
            padding-top: 25px;
            li {
                font-weight: bold;
                font-size: $font-size-26;
                text-transform: initial;
                color: #000;
                line-height: 1.5;
                padding-bottom: 5px;
                padding-left: 40px;
            }
        }
    }
    .right-side {
        width: 50%;
        .main-content {
            position: relative;
            .col-xs-12.txt {
                padding-left: 0;
                img {
                    border: 5px solid #ffdb36;
                }
            }
            .owl-theme {
                .custom-nav {
                    position: absolute;
                    top: 20%;
                    left: 0;
                    right: 0;

                    .owl-prev, .owl-next {
                        position: absolute;
                        height: 100px;
                        color: inherit;
                        background: none;
                        border: none;
                        z-index: 100;

                        i {
                            font-size: 2.5rem;
                            color: #be1e2d;
                        }
                    }
                }
            }
            .arrow.prev.next-vedette {
                position: absolute;
                left: -35px;
                top: 48%;
                z-index: 1;
            }
            .arrow.next.prev-vedette {
                position: absolute;
                right: -20px;
                top: 48%;
                z-index: 1;
            }
        }
    } 
}

#section-burst {
    background: url("../images/accueil_section03_burst_bg.jpg") no-repeat;
    min-height: 300px;
    display: flex;
    align-items: center;
    a.burst {
        width: 33.3333%;
        text-align: center;
        border: 2px solid #fff;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 30px;
        .border {
            background: rgba(0,0,0, 0.5);
            min-height: 110px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid #b6b5b6;
            width: 100%;
            transition: 0.3s;
            h3 {
                text-transform: initial;
                font-size: $font-size-36;
                font-weight: normal;
                color: $color-white;
            }
        }
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            .border {
                background: #ffdb36;
                transition: 0.3s;
                h3 {
                    color: $color-black;
                }
            }
        }
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#section-img-text-text {
    background: #b2b2b2;
    padding: 50px 0;
    .section-img-text {
        display: flex;
        .left-side {
            width: 90%;
            margin-right: 50px;
        }
        h3 {
            text-transform: initial;
            font-size: $font-size-36;
            font-weight: normal;
            color: $color-black;
            text-decoration: underline;
            padding-bottom: 5px;
        }
        p {
            font-size: $font-size-22;
            font-weight: normal;
            color: $color-black;
            text-align: justify;
            margin: 0;
            line-height: 1.5;
        }
    }
    .text-box {
        padding-top: 50px;
        h3 {
            text-transform: initial;
            font-size: $font-size-36;
            font-weight: normal;
            color: $color-black;
            text-decoration: underline;
            padding-bottom: 5px;
        }
        p {
            font-size: $font-size-22;
            font-weight: normal;
            color: $color-black;
            text-align: justify;
            margin: 0;
            line-height: 1.5;
        }
    }
}

#section-carousel-list {
    background: #000;
    padding: 50px 0;
    .main-content {
        position: relative;
        .col-xs-12.txt {
            padding-left: 0;
            img {
                border: 5px solid #ffdb36;
            }
        }
        .owl-theme {
            .custom-nav {
                position: absolute;
                top: 20%;
                left: 0;
                right: 0;

                .owl-prev, .owl-next {
                    position: absolute;
                    height: 100px;
                    color: inherit;
                    background: none;
                    border: none;
                    z-index: 100;

                    i {
                        font-size: 2.5rem;
                        color: #be1e2d;
                    }
                }
            }
        }
        .arrow.prev.next-vedette {
            position: absolute;
            left: -35px;
            top: 48%;
            z-index: 1;
        }
        .arrow.next.prev-vedette {
            position: absolute;
            right: -20px;
            top: 48%;
            z-index: 1;
        }
    }
    .section-list {
        padding-top: 50px;
        h3 {
            text-transform: initial;
            font-size: $font-size-36;
            font-weight: normal;
            color: $color-white;
            text-decoration: underline;
            padding-bottom: 5px;
        }
        ul {
            list-style-image: url(../images/services_section03_point_jaune.jpg);
            padding-left: 30px;
            padding-top: 25px;
            li {
                font-weight: bold;
                font-size: $font-size-26;
                text-transform: initial;
                color: $color-white;
                line-height: 1.5;
                padding-bottom: 5px;
                padding-left: 40px;
            }
        }
        .left-side {
            width: 55%;
        }
    }
}

/*******************************************************************************
* EMPLOIS
*******************************************************************************/

section.emplois-liste {
    background: #b2b2b2;
    padding: 75px 0;
    .item-poste.emploi {
        padding-right: 65px;
        h4 { 
            text-transform: uppercase;
            font-size: $font-size-36;
            font-weight: normal;
            color: $color-black;
            text-decoration: underline;
            padding-bottom: 10px;
        }
        .content-full {
            p {
                font-size: $font-size-22;
                font-weight: normal;
                color: $color-black;
                margin: 0 0 25px 0;
                line-height: 1.5;
            } 
        }
        .btn.emploi {
            width: 50%;
        }
    }
}

section#section-form {
    background: #000;
    padding: 75px 0 0 0;
    h3 {
        text-transform: initial;
        font-size: $font-size-36;
        font-weight: normal;
        color: $color-white;
        text-decoration: underline;
        padding-bottom: 35px;
    }
    label.btn.filedoc {
        background: #b2b2b2;
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#section-realisations {
    background: #000;
    padding: 75px 5%;
    .realisations.zoom-gallery {
        display: flex;
        flex-wrap: wrap;
        a.item {
            width: 33.3333%;
            padding: 0 15px 25px 15px;
            transition: 0.3s;
            .bloc {
                background: #ffdb36;
                padding: 10px;
                h3 {
                    color: $color-black;
                    font-weight: normal;
                    font-size: $font-size-36;
                    text-transform: initial;
                    padding-bottom: 5px;
                }
                .image {
                    position: relative;
                    transition: .5s;
                    .img-responsive {
                        width: 100%;
                    }
                    div.overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        opacity: 0;
                        visibility: hidden;
                        background: rgba(255,219,54, 0.5);
                    }
                }
                p {
                    font-size: $font-size-24;
                    font-weight: normal;
                    color: $color-black;
                    margin: 0;
                    padding: 3px 3px 0 10px;
                    line-height: 1.2;
                }
                &:hover {
                    transition: 0.3s;
                    .image {
                        div.overlay {
                            opacity: 1;
                            width: 100%;
                            visibility: visible;
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    background: #000;
    padding: 50px 0;
    .left-side {
        width: 50%;
        .bloc {
            display: flex;
            align-items: center;
            .icon {
                width: 35%;
            }
            .text { 
                h3 {
                    text-transform: initial;
                    font-size: $font-size-36;
                    font-weight: normal;
                    color: $color-white;
                }
                h4 {
                    font-size: $font-size-28;
                    font-weight: normal;
                    color: $color-white;
                }
            }
        }
        .bloc.last {
            padding-top: 20px;
            .icon {
                width: 26%;
                margin-left: 9%;
            }
        }
    }
    .right-side {
        width: 50%;
    }
}




/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1400px) {
   
#section-coordonnees .left-side .bloc .icon {
    width: 25%;
    margin-right: 35px;
}
#section-coordonnees .left-side .bloc.last .icon {
    width: 15%;
    margin-left: 35px;
}
#section-coordonnees .left-side .bloc .text h4 {
    font-size: 1.2rem;
}
#section-coordonnees .left-side .bloc .text h3 {
    font-size: 1.6rem;
}
}

@media screen and (max-width: 1330px) {
   
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 350px;
}
}

@media screen and (max-width: $size-md-max) {
   
#section-realisations .realisations.zoom-gallery a.item {
    width: 50%;
}
}

@media screen and (max-width: 1024px) {

nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div {
    font-size: 1rem;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 245px;
}
#section-text h3 {
    width: 55%;
}
#section-list-carousel .right-side {
    width: 52%;
}
#section-list-carousel .right-side .main-content .arrow.next.prev-vedette {
    right: 0px;
}
#section-burst a.burst .border h3 {
    font-size: 1.5rem;
}
#section-carousel-list .main-content .arrow.prev.next-vedette, #section-carousel-list .main-content .arrow.next.prev-vedette {
    display: none;
}
.module-header--caption--title {
    font-size: 2.5rem;
}
.module-header--caption {
    width: 60%;
}
section.emplois-liste .item-poste.emploi h4 {
    font-size: 1.6rem;
}
section.emplois-liste .item-poste.emploi .content-full p {
    font-size: 1.05rem;
}
section#section-form .col-sm-push-2 {
    left: 10%;
}
section#section-form form .buttons {
    margin-left: 50px;
}
section#section-form .btn-submit {
    min-width: 300px !important;
}
}

@media screen and (max-width: $size-sm-max) {

nav.module-menu .menu-navbar {
    height: 95px;
}
#section-text h3 {
    width: 65%;
}
#section-text .left-right {
    flex-direction: column;
}
#section-text .left-right .left-side {
    width: 100%;
    margin-right: 0;
    padding-bottom: 25px;
}
#section-text .left-right .right-side {
    width: 100%;
}
#section-text, #section-list-carousel {
    padding: 50px 2%;
}
#section-list-carousel .container.flex {
    flex-direction: column;
}
#section-list-carousel .left-side {
    width: 100%;
    padding-bottom: 25px;
}
#section-list-carousel .right-side {
    width: 75%;
    margin: auto;
}
#section-list-carousel .right-side .main-content .arrow.next.prev-vedette {
    right: -20px;
}
#section-burst a.burst {
    border: none;
}
#section-burst a.burst .border h3 {
    font-size: 1.3rem;
}
#section-img-text-text .section-img-text {
    flex-direction: column;
}
#section-img-text-text .section-img-text .left-side {
    width: 40%;
    margin: 0 auto 25px auto;
}
#section-carousel-list .section-list ul li br {
    display: none;
}
#section-coordonnees .container.flex {
    flex-direction: column;
}
#section-coordonnees .left-side {
    width: 100%;
    margin-bottom: 25px;
}
#section-coordonnees .right-side {
    width: 100%;
}
#section-coordonnees .left-side .bloc .text h4 {
    font-size: 1.4rem;
}
#section-coordonnees .left-side .bloc.last .icon {
    width: 18%;
    margin-left: 50px;
}
section.emplois-liste .container.flex {
    flex-direction: column;
}
section.emplois-liste .item-poste.emploi {
    padding-right: 0;
    padding-bottom: 35px;
}
section.emplois-liste {
    padding: 50px 0 25px 0;
}
section#section-form .col-sm-push-2 {
    left: 5%;
}
section#section-form .col-sm-7, section#section-form .col-sm-8 {
    width: 80%;
}
section#section-form form .buttons {
    margin-left: 70px;
}
#section-realisations {
    padding: 50px 3%;
}
}

@media screen and (max-width: $size-xs-max) {

nav.module-menu .menu-navbar {
    height: 80px;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 175px;
}
#section-text h3 {
    width: 100%;
    font-size: 1.6rem;
    margin-bottom: 30px;
}
#section-list-carousel .right-side {
    width: 95%;
    margin: auto;
}
#section-list-carousel .left-side ul li {
    padding-left: 20px;
}
#section-list-carousel .right-side .main-content .arrow.prev.next-vedette, #section-list-carousel .right-side .main-content .arrow.next.prev-vedette {
    display: none;
}
#section-burst .container.flex {
    flex-direction: column;
}
#section-burst a.burst {
    width: 100%;
    margin-right: 0;
    height: 125px;
}
#section-burst a.burst .border h3 {
    font-size: 1.65rem;
}
#section-burst {
    min-height: 430px;
    background-size: cover;
}
#section-img-text-text .section-img-text .left-side {
    width: 50%;
}
#section-carousel-list .section-list.flex {
    flex-direction: column;
}
#section-carousel-list .section-list .left-side {
    width: 100%;
    padding-bottom: 25px;
}
#section-img-text-text .text-box {
    padding-top: 1.25rem;
}
.module-header--caption--title {
    font-size: 2.3rem;
}
.module-header--caption {
    width: 90%;
    left: 4%;
    top: 85%;
}
#section-coordonnees .left-side .bloc {
    flex-direction: column;
}
#section-coordonnees .left-side .bloc .icon {
    width: 40%;
    margin-right: 0;
    margin-bottom: 15px;
}
#section-coordonnees .left-side .bloc .text h4, #section-coordonnees .left-side .bloc .text h3 {
    text-align: center;
}
#section-coordonnees .left-side .bloc.last .icon {
    width: 22%;
    margin-left: 0;
    margin-bottom: 15px;
}
#section-coordonnees .left-side .bloc.last {
    padding-top: 60px;
}
section#section-form {
    padding: 50px 0 0 0;
}
section#section-form h3 {
    text-align: center;
}
section#section-form .col-sm-7, section#section-form .col-sm-8 {
    width: 100%;
}
section#section-form .col-sm-push-2 {
    left: 0;
}
section#section-form form .buttons {
    margin-left: 0;
}
section.emplois-liste .item-poste.emploi .btn.emploi {
    width: 60%;
}
#section-realisations .realisations.zoom-gallery a.item {
    width: 100%;
}
}