.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 80%;
        left: 22%;
        width: 55%;
        text-align: center;
        transform: translateY(-50%);
        background: rgba(0,0,0, 0.4);
        border-top: 5px solid #ffda35;
        border-bottom: 5px solid #ffda35;

        &--title {
            width: 100%;
            text-align: center;
            font-size: $font-size-60;
            font-weight: normal;
            color: #ffda35;
            padding: 10px 0;
        }
        &--subtitle {
            width: 100%;
            text-align: center;
            font-size: 3vw;
            font-weight: 300;
            color: $color-1;
            text-shadow: 0 0 10px $color-black;
        }

    }
}